import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { HomePage } from './home/home.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  rootPage: any = HomePage;

  constructor(private auth: AuthService) {
    if(auth.isLoggedIn()){
      console.log("logged in");
    } else {
      console.log("not logged in");
    }
  }
}
