import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { SettingService } from '../setting.service';
import { MapComponent } from './map/map.component';
import { SettingsComponent } from './settings/settings.component';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  userId = '';
  devices = [];
  currentDevice = '';
  currentSetting;
  isConnect = "connected";

  currentModal = null;
  pageEl = document.querySelector('.ion-router-outlet');

  connectionCheckInterval;

  constructor(private http: HttpClient,
    public modalController: ModalController,
    private settingsService: SettingService,
    public alertController: AlertController,
    ) {
    this.userId = localStorage.getItem('currentUserId');
    settingsService.addDeviceChangeListener(this);
  }

  changeDevice(){

  }

  setCurrentDevice(device){
    this.currentDevice = device;
  }

  setCurrentSetting(setting){
    this.http.get(`${environment.apiUrl}/${this.settingsService.currentSetting.device}/error/`).subscribe(data => {
      console.log("errordata", data['error']);
      this.settingsService.setCurrentError(data['error']);
    });
    console.log("setCurrentSetting", setting);
    this.currentSetting = setting;
    clearInterval(this.connectionCheckInterval);
    this.connectionCheckInterval = setInterval(() => {
      const checkUrl = `${environment.apiUrl}/${this.settingsService.currentSetting.device}/isconnect`;
      //const checkUrl = environment.apiUrl + "/" + this.settingsService.currentSetting.device + "/isconnect";
      this.http.get(checkUrl).subscribe((data)=>{
        //console.log(data);
        // 계속 연결로 해놓으려면 아래 두 줄을 주석처리하면 됨
        this.isConnect = data['connection'];
        localStorage.setItem('isConnect',this.isConnect);
        if(this.isConnect === 'error'){
          this.presentAlertConfirm(data['code']);
        }
        const isConnect = this.isConnect === 'connected' ? true : false;
        this.settingsService.setConnection(isConnect);
      });
    }, 1000);
  }

  ngOnInit(){
    console.log('maps');
  }

  dismissModal() {
    if (this.currentModal) {
      this.currentModal.dismiss().then(() => {
        this.currentModal = null;
      });
    }
  }


  async openModal(opts = {}) {
    const modal = await this.modalController.create({
      component: 'modal-content',
      ...opts,
    });
    modal.present();

    this.currentModal = modal;
  }

  openSettingsModal(){
    console.log('openSettingsModal');
    this.openModal({
      swipeToClose: true,
      presentingElement: this.pageEl,
    });
  }

  close(){
    this.currentModal.close();
  }
  async presentModal() {
    const modal = await this.modalController.create({
      component: SettingsComponent,
      cssClass: 'my-custom-class',
      breakpoints: [0, 0.2, 0.5, 1],
      initialBreakpoint: 0.5,
    });
    this.currentModal = modal;
    return await modal.present();
  }

  async presentAlertConfirm(errorMessage) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '에러발생',
      message: '에러가 발생하였습니다.<br/> '+errorMessage,
      buttons: [
        {
          text: '확인',
          id: 'confirm-button',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }

  
}
