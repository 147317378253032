import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettingService } from 'src/app/setting.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  devices = [];
  currentDevice;
  currentSetting = {
    device: "",
    laserPower: 1,
    cycle: "",
    place: "",
    errorCode: " "
  };
  map;

  constructor(private modalCtrl: ModalController,
    private service:SettingService) { }

  ngOnInit() {
    console.log("settings");
    this.devices = this.service.getDevices();
    this.currentDevice = this.service.currentDevice;
    this.currentSetting = this.service.currentSetting;
  }

  ionViewDidLoad(){
    
  }

  changeDevice(){
    this.service.setCurrentDevice(this.currentDevice);
    console.log(this.currentDevice);
  }

  changeSetting(){
    this.service.setCurrentSetting(this.currentSetting);
  }
  
  setMapObject(map){
    this.map = map;
  }

  dismiss(){
    console.log('dismiss');
    this.service.map.loadMap();
    this.modalCtrl.dismiss();
  }

}
