import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  map;

  constructor(private http:HttpClient) { 
    const userId = localStorage.getItem('currentUserId');
    this.http.get(environment.apiUrl+'/v2/accesscontrols/').subscribe(datas => {
      Object.entries(datas).forEach(data => {
        console.log(data);
        const access = data[1];
        if(access.userId === userId && access.canAccess === 1){
          this.devices.push(access.deviceId);
          console.log(this.currentSetting.device == "");
          if(this.currentSetting.device == ""){
            this.currentSetting.device = access.deviceId;          
            console.log("[Service]setting = setcurrentseting");
            this.setCurrentSetting(this.currentSetting);
          }
          this.map.loadMap();
        }
        //this.currentSetting.device = this.devices[0];
        //this.setCurrentSetting(this.currentSetting);
      });
    })
  }
  
  devices = [];
  listeners = [];

  public currentDevice = "";
  public currentSetting = {
    device: "",
    laserPower: 1,
    cycle: "1",
    place: "장소",
    errorCode: " ",
  };;

  setCurrentDevice(device){
    this.currentDevice = device;
    this.listeners.forEach(listener =>{
      listener.setCurrentDevice(device);
    });
  }


  setCurrentSetting(setting) {
    this.currentSetting = setting;    
    console.log(this.currentSetting);
    
    this.listeners.forEach(listener =>{
      listener.setCurrentSetting(setting);
    });
  }
  setCurrentError(error_code) {
    this.currentSetting.errorCode = error_code;
  }

  getDevices(){
    return this.devices;
  }

  addDeviceChangeListener(target){
    this.listeners.push(target);
  }

  setConnection(connection){
    this.map.isConnect = connection;
  }
  setMapObject(map){
    this.map = map;
  }

}
